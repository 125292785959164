



























import Vue from 'vue'
import { handleDocumentCategoryCreation } from '@api/addy-plus/corporations'
import Flash from '@components/modal/slide-down-flash.vue'
import { IData, IMethods, IComputed, IProps } from '@/types/addy_plus/corporations/data_room/AddCategoryNType'
import { capitalizeFirstLetterOfEachWord } from '@utils/common-methods/common'

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  components: {
    Flash,
  },
  props: {
    department: {
      type: String,
      validator(value): boolean {
        return ['legal', 'finance', 'real estate'].includes(value)
      },
    },
    existingCategories: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      category: '',
      type: '',
      showFlash: false,
      timer: null,
    }
  },
  computed: {
    corporationId() {
      return this.$route.params.corporationId
    },
    hasUnsavedChanges() {
      return !!(this.category || this.type)
    },
    filteredCategoryArray() {
      return this.existingCategories.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.category.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    onTypeCreation() {
      const category = capitalizeFirstLetterOfEachWord(this.category)
      const type = capitalizeFirstLetterOfEachWord(this.type)
      handleDocumentCategoryCreation(this.corporationId, category, type, this.department).then((res) => {
        if (!res.success) return
        this.showSuccessFlash()
        this.$emit('onDocumentCategoryChange', res.data)
        this.resetFields()
      })
    },
    resetFields() {
      this.category = ''
      this.type = ''
    },
    showSuccessFlash() {
      this.showFlash = true
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.showFlash = false
    },
  },
})
