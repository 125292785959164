<template>
  <div>
    <Permission/>
    <Breadcrumb/>
    <div class="title is-capitalized">{{department}} Data Room</div>
    <div class="subtitle">{{corporationName}}</div>
    <Tabs ref="corporationTabs"/>
    <b-field label="Choose Your Department" class="choose-department">
      <b-select v-model="department" @input="onDepartmentChange">
        <option
          v-for="option in departmentOptions"
          :value="option"
          :key="option">
          {{ option | categoryFormatter }}
        </option>
      </b-select>
    </b-field>
    <section class="mb-5">
      <DropNUpload
        ref="dropNUpload"
        feature="corpDataRoom"
        :fileCategories="fileCategories"
        :corporateDepartment="parameterizedDepartment"
        :key="dropNUploadComponentKey"
        :editable="canCreate"
        @onSubmit="onSubmit"
      ></DropNUpload>
    </section>
    <b-progress
      :value="loadedPercentage"
      type="is-blue"
      format="percent"
      show-value
      v-show="showProgressBar"
    ></b-progress>
    <section>
      <AddCategoryNType
        ref="categoryNType"
        :department="department"
        :existingCategories="existingCategories"
        @onDocumentCategoryChange="onDocumentCategoryChange"
      ></AddCategoryNType>
    </section>
    <section>
      <div
        class="document-category"
        v-for="(category, key) in documents"
        :key="key"
      >
        <h3 class="category-name has-text-weight-bold pt-5 pb-3">{{key}}</h3>
        <FileList
          v-for="(type, typeName) in category"
          :key="typeName"
          :fileType="typeName"
          :files="type"
          :canDelete="canDelete"
          @onFileDeletion="openDeleteModal"
          @onFilePreview="onFilePreview"
          @onFileUpdate="onFileUpdate"
          @drop="(event) => { onDrop(event, typeName) }"
        ></FileList>
      </div>
    </section>
    <FilePreviewModal :fileUrl="previewUrl" :fileType="previewFileType" @closeModal="onFilePreviewModalHide"></FilePreviewModal>
    <Flash title="Congrats" :content="flashContent" :show="showFlash" :hideFlash="hideFlash"></Flash>
    <ConfirmModal
      :title="confirmModalContent.title"
      :text="confirmModalContent.text"
      :showModal="showConfirmModal"
      :continueButtonText="confirmModalContent.button"
      :hasCloseIcon="false"
      :hasClickableBackground="false"
      :hasCancelButtonOnLeft="true"
      @cancelAction="confirmModalCancelAction"
      @doContinue="confirmModalContinueAction"
    ></ConfirmModal>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import Tabs from '@views/addy_plus/corporations/Tabs.vue'
import DropNUpload from '@components/upload/drop-n-upload.vue'
import FileList from './FileList.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import FilePreviewModal from '@components/modal/file-preview-modal.vue'
import AddCategoryNType from './AddCategoryNType.vue'
import fileTypeMixin from '@utils/mixins/file-type-mixin.js'
import { handleCorporateDataRoomFiles as handleCorpFile, fetchCorporateDataRoomFiles, fetchDocumentCategories } from '@api/addy-plus/corporations'
import { fetchDocUrl } from '@api/common'
import ConfirmModal from '@components/modal/continue-or-cancel-modal.vue'
import Permission from '@views/addy_plus/base/Permission.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import { capitalizeFirstLetterOfEachWord } from '@utils/common-methods/common'

const DEPARTMENTS = ['legal', 'finance', 'real estate']

export default {
  title: 'Corporation Data Room',
  mixins: [pageTitleMixin, corpDetailsMixin, fileTypeMixin],
  components: {
    Permission,
    Tabs,
    DropNUpload,
    FileList,
    Flash,
    FilePreviewModal,
    AddCategoryNType,
    ConfirmModal,
    Breadcrumb,
  },
  data() {
    return {
      dropNUploadComponentKey: 0,
      showFlash: false,
      timer: null,
      documents: {},
      showProgressBar: false,
      loadedPercentage: 0,
      previewUrl: '',
      previewFileType: 'image',
      categories: {},
      showConfirmModal: false,
      confirmedRedirection: false,
      redirectFullPath: '',
      flashContent: '',
      departmentOptions: Object.freeze(DEPARTMENTS),
      department: DEPARTMENTS[0],
      confirmModalType: '', // 'leave', 'move', 'delete'
      fileToSave: {},
      fileToSaveType: '',
      fileIdToDelete: null,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    corporationId() {
      return this.$route.params.corporationId
    },
    existingCategories() {
      return Object.keys(this.categories)
    },
    fileCategories() {
      const options = []
      for (const key in this.categories) {
        if (Object.hasOwnProperty.call(this.categories, key)) {
          const fileTypes = this.categories[key].map((type) => {return { id: type, label: type }})
          options.push({ id: key, label: key, children: fileTypes })
        }
      }
      return options
    },
    hasUnsavedChanges() {
      return this.$refs.dropNUpload.hasUnsavedChanges || this.$refs.categoryNType.hasUnsavedChanges
    },
    parameterizedDepartment() {
      return this.department.replace(/\s/g, '_')
    },
    canCreate() {
      return this.permissions.entityDataRoom?.includes('create')
    },
    canUpdate() {
      return this.permissions.entityDataRoom?.includes('update')
    },
    canDelete() {
      return this.permissions.entityDataRoom?.includes('delete')
    },
    confirmModalContent() {
      const content = {
        leave: {
          title: 'Leave page?',
          text: 'Are you sure you want to leave this page? There are pending uploads and your data will be lost.',
          button: 'Confirm and leave',
        },
        move: {
          title: 'Move file?',
          text: `Are you sure you want to change the file type to "${this.fileToSaveType}"?`,
          button: 'Confirm and move',
        },
        delete: {
          title: 'Delete file?',
          text: 'Are you sure you want to delete this file? This action cannot be undone.',
          button: 'Confirm and delete',
        }
      }

      return content[this.confirmModalType] || {}
    },
  },
  mounted() {
    this.chooseDepartment()
    this.fetchFiles()
    this.fetchDocCategories()
    this.initPageGuards()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasUnsavedChanges || this.confirmedRedirection) {
      next()
    } else {
      // let confirm modal handle redirection
      this.redirectFullPath = to.fullPath
      this.confirmModalType = 'leave'
      this.showConfirmModal = true
      return false
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  filters: {
    categoryFormatter(value) {
      return capitalizeFirstLetterOfEachWord(value)
    },
  },
  methods: {
    ...mapMutations('addyPlusDataRoom', ['setSelectedDepartment']),
    chooseDepartment() {
      const department = this.$route.query.department
      if (!department) return

      this.department = department.trim().replace(/_/g, ' ')
    },
    fetchFiles() {
      fetchCorporateDataRoomFiles(this.corporationId, this.parameterizedDepartment).then((res) => {
        if (!res.success) return
        this.documents = res.data
        this.handleAutoScroll()
      })
    },
    fetchDocCategories() {
      fetchDocumentCategories(this.corporationId, this.parameterizedDepartment).then((res) => {
        if (!res.success) return
        this.categories = res.data
      })
    },
    handleAutoScroll() {
      const targetId = this.$route.query.target
      if (targetId) {
        this.$nextTick(() => {
          const target = document.getElementById(targetId)
          target && target.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    onSubmit(formData) {
      this.showProgressBar = true
      handleCorpFile(this.corporationId, formData, this.onUploadProgress).then((res) => {
        if (!res.success) return
        this.onFlashShowing('uploaded')
        this.dropNUploadComponentKey++
        this.fetchFiles()
        this.$router.replace({ query: { category: null } })
      }).finally(() => {
        this.showProgressBar = false
      })
    },
    onUploadProgress(progressEvent) {
      const { loaded, total } = progressEvent
      this.loadedPercentage = loaded / total * 100
    },
    showSuccessFlash() {
      this.showFlash = true
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.showFlash = false
    },
    openDeleteModal(fileId) {
      this.confirmModalType = 'delete'
      this.fileIdToDelete = fileId
      this.showConfirmModal = true
    },
    onFileDeletion(fileId) {
      const payload = { corporation: { '0': { id: fileId, _destroy: true } } }

      handleCorpFile(this.corporationId, payload, () => {}).then((res) => {
        if (!res.success) return
        this.onFlashShowing('deleted')
        this.fetchFiles()
      })
    },
    getFileTypeFromName(fileName) {
      // types: 'image', 'pdf', 'xls', 'doc'
      if (this.isImage(fileName)) {
        return 'image'
      } else if (this.isDoc(fileName)) {
        return 'doc'
      } else if (this.isPdf(fileName)) {
        return 'pdf'
      } else if (this.isExcel(fileName)) {
        return 'xls'
      } else {
        return 'image'
      }
    },
    onFilePreview({ hashid, fileName }) {
      this.previewFileType = this.getFileTypeFromName(fileName)
      if (!['image', 'pdf'].includes(this.previewFileType)) {
        this.previewUrl = 'use_default' // previewUrl is only used to toggle the preview modal for doc or excel
        return
      }

      fetchDocUrl({
        hashid,
        model_name: 'EncryptedUpload',
        document_type: 'file',
      }).then((res) => {
        if (!res.success) return
        this.previewUrl = res.data.url
      })
    },
    onFilePreviewModalHide() {
      this.previewUrl = ''
    },
    onDocumentCategoryChange(categories) {
      this.categories = categories
      this.fetchFiles()
    },
    initPageGuards() {
      window.addEventListener('beforeunload', this.onBeforeUnload)
    },
    onBeforeUnload(event) {
      if (this.hasUnsavedChanges) {
        event.preventDefault() // this is required for Firefox and Safari
        event.returnValue = '' // this is required for Chrome, Edge and Opera
        return event.returnValue // this is optional
      }
    },
    redirectToPath() {
      this.confirmedRedirection = true
      this.$router.push(this.redirectFullPath)
    },
    cancelRedirection() {
      // manually setting the menu to current tab, overriding the b-tab's default click behavior
      this.$nextTick(() => {
        this.$refs.corporationTabs.setActiveTab()
      })
    },
    onFileUpdate(payload) {
      handleCorpFile(this.corporationId, payload, () => {}).then((res) => {
        if (!res.success) return
        this.onFlashShowing('updated')
        this.fetchFiles()
      })
    },
    onFlashShowing(type) {
      this.flashContent = `Successfully ${type}!`
      this.showSuccessFlash()
    },
    onDepartmentChange(department) {
      this.setSelectedDepartment(department)
      this.fetchFiles()
      this.fetchDocCategories()
    },
    onDrop(event, typeName) {
      const fileToSave = JSON.parse(event.dataTransfer.getData('file'))
      if (fileToSave.fileCategory === typeName) return

      this.fileToSave = fileToSave
      this.fileToSaveType = typeName
      this.confirmModalType = 'move'
      this.showConfirmModal = true
    },
    moveFile() {
      const formData = new FormData()
      formData.append('corporation[0][id]', this.fileToSave.id)
      formData.append('corporation[0][file_category]', this.fileToSaveType)
      // in case the file has unsaved changes
      this.fileToSave.fileLabel && (formData.append('corporation[0][file_label]', this.fileToSave.fileLabel))
      this.fileToSave.notes && (formData.append('corporation[0][notes]', this.fileToSave.notes))
      this.onFileUpdate(formData)
      this.closeConfirmModal()
    },
    closeConfirmModal() {
      this.redirectFullPath = ''
      this.fileToSave = {}
      this.fileToSaveType = ''
      this.fileIdToDelete = null
      this.confirmModalType = ''
      this.showConfirmModal = false
    },
    confirmModalCancelAction() {
      if (this.confirmModalType === 'leave') this.cancelRedirection()
      this.closeConfirmModal()
    },
    confirmModalContinueAction() {
      if (this.confirmModalType === 'leave') {
        this.redirectToPath()
      } else if (this.confirmModalType === 'move') {
        this.moveFile()
      } else if (this.confirmModalType === 'delete') {
        this.deleteFile()
      }
    },
    deleteFile() {
      this.onFileDeletion(this.fileIdToDelete)
      this.closeConfirmModal()
    },
  },
}
</script>
<style lang="scss" scoped>
.choose-department {
  margin-bottom: 36px;
  ::v-deep select {
    width: 167px;
  }
}
.b-tabs {
  margin-bottom: 0;
}
.progress-wrapper {
  margin-top: 30px;
}
.document-category {
  .category-name {
    font-size: 22px;
    border-bottom: 2px solid #f5f5f5;
  }
}
</style>
