





































import Vue from 'vue'
import { fetchDocUrl } from '@/api/common'
import { downloadFile } from '@utils/common-methods/common'
import { mapState } from 'vuex'
import File from '@views/addy_plus/properties/due_diligence/File.vue'
import { EncryptedUpload } from '@/types/helpers/common'

export default Vue.extend({
  components: {
    File,
  },
  props: {
    files: Array,
    fileType: {
      type: String,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      anchorLinkCopied: false as boolean,
      tooltipIsActive: false as boolean,
      showEllipsisTooltip: false as boolean,
      fileEntersDropZone: false as boolean,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    targetId(): string | (string | null)[] | undefined {
      return this.$route.query.target
    },
  },
  methods: {
    onFileDownload(hashid: string, fileName: string): void {
      this.fetchEncryptedDoc(hashid).then((url) => downloadFile(url, fileName))
    },
    fetchEncryptedDoc(fileId: string): Promise<string> {
      return new Promise((resolve) => {
        fetchDocUrl({
          hashid: fileId,
          model_name: 'EncryptedUpload',
          document_type: 'file',
        }).then((res) => {
          if (res.success) resolve(res.data.url)
        })
      })
    },
    onAnchorLinkCopy(hashid: string): void {
      const link = `${location.origin}${this.$route.path}?target=${hashid}`
      this.$copyText(link).then(() => {
        this.anchorLinkCopied = true
      })
    },
    onTooltipTriggered(): void {
      this.anchorLinkCopied = false
      this.tooltipIsActive = true
    },
    onTooltipClose(): void {
      this.tooltipIsActive = false
    },
    onFileUpdate(payload: FormData): void {
      this.$emit('onFileUpdate', payload)
    },
    onFileDeletion(fileId: number): void {
      this.$emit('onFileDeletion', fileId)
    },
    onFilePreview(payload: { hashid: string, fileName: string }): void {
      this.$emit('onFilePreview', payload)
    },
    onEllipsisTooltipTriggered(event: any): void {
      this.showEllipsisTooltip = event.target.offsetWidth < event.target.scrollWidth
    },
    onEllipsisTooltipClose(): void {
      this.showEllipsisTooltip = false
    },
    onDragEnter(event: DragEvent): void {
      event.preventDefault()
      this.fileEntersDropZone = true
    },
    onDragStart(event: DragEvent, file: EncryptedUpload): void {
      if (!event.dataTransfer) return

      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('file', JSON.stringify(file))
    },
    onDrop(event: DragEvent): void {
      this.$emit('drop', event)
      this.fileEntersDropZone = false
    },
  },
})
