





























































































































import Vue from 'vue'
import CATEGORIES from '@/utils/data/due_diligence_uploads_categories'
import { downloadFile } from '@utils/common-methods/common'
import { fetchDocUrl } from '@api/common'
import { mapGetters } from 'vuex'
import { IData, IMethods, IComputed, IProps } from '@/types/addy_plus/properties/File'

const LOGOS = {
  word: 'https://addy-public.s3.us-west-2.amazonaws.com/word-logo.png',
  pdf: 'https://addy-public.s3.us-west-2.amazonaws.com/pdf-logo.png',
  excel: 'https://addy-public.s3.us-west-2.amazonaws.com/excel-logo.png',
  default: 'https://addy-public.s3.us-west-2.amazonaws.com/basic-file.png',
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  data() {
    return {
      categories: Object.freeze(CATEGORIES) as string[],
      id: 0,
      hashid: '',
      fileLabel: '',
      file: '',
      fileCategory: '',
      fileName: '',
      dateReceived: null,
      uploadedOn: null,
      applicableDate: null,
      receivedFrom: '',
      notes: '',
      confidential: false,
      anchorLinkCopied: false,
      tooltipIsActive: false,
      showEllipsisTooltip: false,
    }
  },
  props: {
    data: Object,
    permissions: {
      type: Array,
      default: () => [],
    },
    ownable: {
      type: String,
      validator: (value: string): boolean => ['property', 'corporation'].includes(value),
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('addyPlusDataRoom', ['parsedDepartment']),
    hasOnlyRead() {
      return this.permissions.includes('read') && this.permissions.length === 1
    },
    canUpdate() {
      return this.permissions.includes('update')
    },
    canDelete() {
      return this.permissions.includes('delete')
    },
    ownedByProperty() {
      return this.ownable === 'property'
    },
    ownedByCorporation() {
      return this.ownable === 'corporation'
    },
    updateButtonDisabled() {
      return !this.fileLabel || (this.ownedByProperty && (!this.dateReceived || !this.fileCategory))
    }
  },
  mounted() {
    this.populateFields()
  },
  methods: {
    populateFields() {
      for (const key in this.data) {
        if (Object.hasOwnProperty.call(this.data, key)) {
          // TODO - remove this ignore
          // @ts-ignore
          this[key] = ['dateReceived', 'applicableDate', 'uploadedOn'].includes(key) ? this.parseDate(this.data[key]) : this.data[key]
        }
      }
    },
    getPreview(url) {
      if (/\.(jpe?g|png|gif)/i.test(url)) {
        return url
      } else if (/\.(doc|docx)/i.test(url)) {
        return LOGOS.word
      } else if (/\.(pdf)/i.test(url)) {
        return LOGOS.pdf
      } else if (/\.(xls|xlsx)/i.test(url)) {
        return LOGOS.excel
      } else {
        return LOGOS.default
      }
    },
    onFileDeletion() {
      this.$emit('onFileDeletion', this.id)
    },
    onFileUpdate() {
      if (this.updateButtonDisabled) return

      const formData = new FormData()
      formData.append(`${this.ownable}[0][id]`, this.id.toString())
      this.fileLabel && (formData.append(`${this.ownable}[0][file_label]`, this.fileLabel))
      this.fileCategory && (formData.append('property[0][file_category]', this.fileCategory))
      this.dateReceived && (formData.append('property[0][date_received]', this.dateReceived))
      this.receivedFrom && (formData.append('property[0][received_from]', this.receivedFrom))
      this.ownedByProperty && formData.append('property[0][confidential]', this.confidential.toString())
      this.notes && (formData.append('corporation[0][notes]', this.notes))

      this.$emit('onFileUpdate', formData)
    },
    onFileDownload() {
      fetchDocUrl({
        hashid: this.hashid,
        model_name: 'EncryptedUpload',
        document_type: 'file',
      }).then((res) => {
        if (!res.success) return
        downloadFile(res.data.url, this.fileLabel)
      })
    },
    onFilePreview() {
      this.$emit('onFilePreview', { hashid: this.hashid, fileName: this.fileName })
    },
    onEllipsisTooltipTriggered(event) {
      this.showEllipsisTooltip = event.target.offsetWidth < event.target.scrollWidth
    },
    onEllipsisTooltipClose() {
      this.showEllipsisTooltip = false
    },
    onAnchorLinkCopy(hashid) {
      let link = `${location.origin}${this.$route.path}?target=${hashid}`
      if (this.ownedByCorporation) link = `${link}&department=${this.parsedDepartment}`
      this.$copyText(link).then(() => {
        this.anchorLinkCopied = true
      })
    },
    onTooltipTriggered() {
      this.anchorLinkCopied = false
      this.tooltipIsActive = true
    },
    onTooltipClose() {
      this.tooltipIsActive = false
    },
    onDragStart(event: DragEvent): void {
      this.$emit('dragstart', event)
    },
    parseDate(dateString: string): Date | null {
      if (!dateString) return null

      return new Date(dateString.replaceAll('-', '/'))
    },

  },
})
